<template>
  <b-form-group :label-for="field.key" :class="field.class">
    <b-row align-v="center" class="mb-1">
      <b-col>
        <label class="mb-0">
          {{ $t(field.label) }}
        </label>
      </b-col>
      <b-col class="text-right">
        <b-button variant="primary" size="sm" pill @click="addRow">
          <feather-icon icon="PlusCircleIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row v-for="(tool, idx) in value" :key="`live-${idx}`" class="live-group">
      <b-col md="12" class="text-right">
        <b-button
          variant="outline-danger"
          size="sm"
          pill
          @click="removeRow(idx)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </b-col>
      <b-col md="4">
        <b-form-group :label-for="`live-name-en-${idx}`">
          <validation-provider
            #default="{ errors }"
            :vid="`live-name-en-${idx}`"
            :name="$t('field.nameEn')"
            :rules="`required|max:100`"
          >
            <label> {{ $t("field.nameEn") }} </label>
            <b-form-input
              v-model="tool.nameEn"
              :placeholder="$t('field.nameEn')"
              :name="`live-name-en-${idx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label-for="`live-name-kh-${idx}`">
          <validation-provider
            #default="{ errors }"
            :vid="`live-name-kh-${idx}`"
            :name="$t('field.nameKh')"
            :rules="`required|max:100`"
          >
            <label> {{ $t("field.nameKh") }} </label>
            <b-form-input
              v-model="tool.nameKh"
              :placeholder="$t('field.nameKh')"
              :name="`live-name-kh-${idx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :label-for="`live-link-${idx}`">
          <validation-provider
            #default="{ errors }"
            :vid="`live-link-${idx}`"
            :name="$t('field.videoLink')"
            :rules="`required|max:200`"
          >
            <label> {{ $t("field.videoLink") }} </label>
            <b-form-input
              v-model="tool.videoLink"
              :placeholder="$t('field.videoLink')"
              :name="`live-link-${idx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="auto">
        <b-form-group :label-for="`live-status-${idx}`">
          <b-form-checkbox
            :id="`live-status-${idx}`"
            v-model="tool.status"
            :name="`live-status-${idx}`"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t("status.active") }}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
  },
  props: {
    value: {},
    field: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    addRow() {
      let tools = [...this.value];

      tools.push({
        nameEn: null,
        nameKh: null,
        videoLink: null,
        status: true,
      });

      this.$emit("input", tools);
    },
    removeRow(idx) {
      let tools = [...this.value];

      tools.splice(idx, 1);

      this.$emit("input", tools);
    },
  },
};
</script>
export default [
  {
    key: 'nameEn',
    label: 'field.nameEn',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'nameKh',
    label: 'field.nameKh',
    rules: 'required|max:100',
    type: 'text',
  },
  {
    key: 'status',
    label: 'status.active',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'tools',
    label: 'field.live',
    rules: 'required',
    cols: 12,
  },
]
